body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

.login {
  position: fixed;
  background-size: cover;
  /* background-color: #212529; */
  width: 100%;
  height: 100%;
}

.middle {
  vertical-align: middle;
}

form {
  color: #f4f4f4;
}

.site-form-item-icon, .ant-input-affix-wrapper {
  color: #d9d9d9;
}

.plain{
  background-color: #ffffff;
}

.green {
  /* background-color: #47df89; */
  background-color: #009a44;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
}

.orange {
  /* background-color: #f8af6c; */
  background-color: #f68d2e;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
}

.red {
  /* background-color: #eb4545; */
  background-color: #bc204b;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
}

li, .ant-menu-item:selected {
  background: #005EB8 !important;
}

Button.view {
  background-color: #005EB8 !important;
}

Button.view:hover {
  background-color: #0091da !important;
}

Button.change-resp {
  background-color: #0091da;
  border-color: #0091da;
  color: #ffffff;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

@media only screen and (max-width:1026px){
  #btn-web {
    display: none;
  }
}

@media only screen and (min-width: 1024px){
  #btn-mobile{
    visibility: hidden;
  }
  #btn-web{
    display: inline-flex;
  }
}

Alert.description {
  color: #fff;
}

Button.confirm-resp {
  background-color: #005EB8;
  border-color: #005EB8;
  color: #ffffff;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

.health {
  /* color: #848484; */
  color: #626262;
  font-weight: bold;
}

.management {
  /* color: #0873d6; */
  color: #005EB8;
  font-weight: bold;
}

Button.login-form-button {
  background-color: #005EB8;
}

Button.login-form-button:hover {
  background-color: #0091da;
}

Button.safe-form-button, Button.safe-form-button:focus, Button.safe-form-button:active, Button.safe-form-button:visited, #success {
  color: #fff;
  background-color: #51a351;
  border-color: #51a351;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.safe-form-button:hover {
  color: #fff;
  background-color: #75d175;
  border-color: #75d175;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.view-form-button, Button.view-form-button:focus, #view {
  color: #fff;
  background-color: #027032;
  border: #027032;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.view-form-button:hover {
  color: #fff;
  background-color: #24bb65;
  border: #24bb65;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.viewrisk-form-button, Button.viewrisk-form-button:focus, #viewrisk {
  color: #fff;
  background-color: #e26d06;
  border: #e26d06;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.viewrisk-form-button:hover {
  color: #fff;
  background-color: #f37609;
  border: #f37609;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.viewhelp-form-button, Button.viewhelp-form-button:focus, #viewhelp {
  color: #fff;
  background-color: #e43667;
  border: #e43667;
  /* background-color: #a70000;
  border: #a70000; */
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.viewhelp-form-button:hover {
  color: #fff;
  background-color: #e63131;
  border: #e63131;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.risk-form-button, Button.risk-form-button:focus, Button.risk-form-button:active, Button.risk-form-button:visited, #warning {
  background-color: #f89406;
  border-color: #f89406;
  color: #ffffff;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.risk-form-button:hover, #warning {
  background-color: #fbb656;
  border-color: #fbb656;
  color: #ffffff;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.help-form-button, Button.help-form-button:focus, #danger {
  background-color: #bd362f;
  border-color: #bd362f;
  color: #ffffff;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.help-form-button:hover, #danger {
  background-color: #f0665f;
  border-color: #f0665f;
  color: #ffffff;
  border-radius: 10px;
  height: 50px;
  width: 100%;
}

Button.SOS, Button.SOS:focus, Button.SOS:active{
  background-color: #bd362f;
  border-color: #bd362f;
  color: #fff;
}

Button.SOS:hover{
  background-color: #f0665f;
  border-color: #f0665f;
  color: #fff;
}

.SOS, .SOS:focus, .SOS:active{
  background-color: #bd362f;
  border-color: #bd362f;
  color: #fff;
}

.SOS:hover{
  background-color: #f0665f;
  border-color: #f0665f;
  color: #fff;
}

#transparent {
  background-color: transparent !important;
}

img.logo {
  border-radius: 100%;
}

Title {
  color: #848484;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.check-icon {
  font-size: 200%;
}

.white {
  color: #fff;
}

.site-layout-content {
  background: #fff;
  min-height: calc(100vh - 158px);
}

.anticon{
  display: inline-flex !important;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 300px;
}

.progress-done {
  background: linear-gradient(to left, #F2709C, #FF9472);
  box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.sos-modal .ant-modal-footer{
  visibility:hidden;
}

.ant-layout-header {
  background: #89c140 !important;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: #89c140 !important;
}